<header class="top-section text-center text-white pt-24 mb-24">
  <div class="container">
    <img src="/assets/customerportallogo.png" alt="" class="mb-24">
    <h1 class="mat-display-1 mb-8">Congratulations!</h1>
    <h2 class="mat-headline">You have created your Customer Portal. It will take a while before it's ready</h2>
    <h2 class="mat-headline">In the <strong>meantime you can customize it.</strong></h2>
  </div>
</header>

<div class="container">
  <mat-card class="mb-24 mat-card-no-padding mt-n56">
    <div class="p-16 pb-12">
      <h4 class="mat-caption">Order Summary</h4>
      <p class="mat-subheading-2 mb-0">1x Customer Portal</p>
      <p class="mat-caption mb-0 text-gray-dark">www.<span class="text-black">{{ yourCustomPortalName }}</span>.onedayportal.com</p>
    </div>
    <mat-divider></mat-divider>
    <div class="p-16 pt-12" fxLayout="row" fxLayoutGap="16px">
      <div>
        <p class="mat-caption mb-0">One time fee:</p>
        <p class="mat-body-2 mb-0">$0.00</p>
      </div>
      <div>
        <p class="mat-caption mb-0">Recurring payment:</p>
        <p class="mat-body-2 mb-0">$100.00/m</p>
      </div>
    </div>
  </mat-card>

  <div class="text-center">
    <a href="//{{currentInstanceManagerUrl$|async}}" mat-raised-button color="primary">MY CUSTOMER PORTAL</a>
  </div>
</div>
