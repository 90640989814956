import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../shared/order.service';
import { Router } from '@angular/router';
import { ElementOptions, ElementsOptions, StripeCardComponent, StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss']
})
export class CreateInstanceComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  form: FormGroup;
  loading = false;
  loadingOrder = false;
  cardError = true;
  cardProblem = '';
  showCardForm = false;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private orderService: OrderService,
    private stripeService: StripeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', {validators: [Validators.required]}),
      terms: new FormControl(false, {validators: [Validators.required]}),
    });
  }

  // TODO: please check this is ok
  order(): void {
    this.loadingOrder = true;
    this.orderService.checkDomain(this.name.value).subscribe(
      () => this.showCardForm = true,
      (error) => {
        if (error.status == 400) {
          this.name.setErrors({'incorrect': true});
        }
        this.loadingOrder = false;
      },
      () => this.loadingOrder = false
    );
  }

  clear(): void {
    this.showCardForm = false;
    this.form.reset({name: '', terms: false});
  }

  // TODO: please check this is ok
  onSubmit(): void {
    this.loading = true;

    this.stripeService.createToken(this.card.getCard(), {}).subscribe(result => {
      this.cardProblem = '';
      if (result.token) {
        this.loading = true;
        this.orderService.createInstance({name: this.name.value, paymentToken: result.token.id}).subscribe(
          () => this.router.navigateByUrl(`/created/${this.name.value}`),
          (err) => {
            this.loading = false;
            if (err.error.quantity) {
              this.cardProblem = err.error.quantity;
            } else if (err.error.paymentError) {
              this.cardProblem = err.error.paymentError;
            }
          },
          () => this.loading = false
        );
      } else if (result.error) {
        this.loading = false;
        this.cardProblem = 'Payment Token is invalid';
      }
    });
  }

  onChange({ type, event}) {
    if (type === 'change') {
      this.cardError = !event.complete;
    }
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }
}
