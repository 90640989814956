import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { CurrentUserService } from '../shared/current-user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-created',
  templateUrl: './created.component.html',
  styleUrls: ['./created.component.scss']
})
export class CreatedComponent implements OnInit {
  yourCustomPortalName: string;
  currentInstanceManagerUrl$ = this.currentUser.currentUser$.pipe(
    map((user) => user.actualCustomerInstanceManagerUrl)
  );

  constructor(private currentUser: CurrentUserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.yourCustomPortalName = params['name'])
  }
}
