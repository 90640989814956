import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateInstanceComponent } from './create-instance/create-instance.component';
import { CreatedComponent } from './created/created.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  { path: '', component: CreateInstanceComponent, canActivate: [AuthGuard] },
  { path: 'created/:name', component: CreatedComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
