import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from './shared/current-user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  date = new Date();
  constructor(private currentUser: CurrentUserService) {}

  currentPortalUrl$ = this.currentUser.currentUser$.pipe(
    map((user) => user.actualCustomerPortalUrl)
  );
}
