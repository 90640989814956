<header class="top-section text-white pt-24 mb-24">
  <div class="container text-center">
    <img src="/assets/customerportallogo.png" alt="Customer Portal logo" class="mb-16">
    <h1 class="mat-display-1 mb-8">Customer Portal Instance</h1>
    <h2 class="mat-headline">Join the community and start building your client network to sell products</h2>
    <h2 class="mat-headline">for just <strong>$100 per month</strong></h2>
  </div>
</header>

<div class="container">
  <mat-card class="mt-n56">
    <form [formGroup]="form" class="mx-a mt-16 text-left form-mb-lg-sm">
      <div fxLayout="row wrap" fxLayoutAlign="center space-between" fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.gt-xs="34px">
        <div fxFlex.lt-sm="100%" fxFlex ngClass.lt-sm="mb-4">
          <p class="mat-caption cp-label">Enter your Customer Portal name</p>
          <mat-form-field [hideRequiredMarker]="true" floatLabel="never" class="full-width">
            <input matInput formControlName="name" placeholder="yourcustomerportal" required pattern="[a-zA-Z0-9-]{1,64}" [readonly]="showCardForm">
            <span matSuffix class="text-gray-dark">.onedayportal.com</span>
            <mat-error *ngIf="name.hasError('required') && name.touched">Please provide valid domain name</mat-error>
            <mat-error *ngIf="name.hasError('pattern') && name.touched">Please provide valid domain name</mat-error>
            <mat-error *ngIf="name.hasError('incorrect') && name.touched">Name already taken, try a different one</mat-error>
          </mat-form-field>
        </div>

        <button *ngIf="!showCardForm; else displayOrderButton" class="order-btn" mat-raised-button color="primary" type="button" (click)="order()" [disabled]="!form.valid || loadingOrder">
          <ng-container *ngIf="!loadingOrder; else spinnerOrder">ORDER</ng-container>
          <ng-template #spinnerOrder><mat-spinner [diameter]="24"></mat-spinner></ng-template>
        </button>

        <ng-template #displayOrderButton>
          <button class="order-btn" mat-raised-button type="button" (click)="clear()" [disabled]="loading">
            <ng-container *ngIf="!loadingOrder; else spinnerOrder">CLEAR</ng-container>
            <ng-template #spinnerOrder><mat-spinner [diameter]="24"></mat-spinner></ng-template>
          </button>
        </ng-template>
      </div>
    </form>

    <div *ngIf="showCardForm" class="card-mt-lt-sm">
      <p class="mat-caption">Payment Information</p>

      <ngx-stripe-card (on)="onChange($event)" [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
      <mat-error *ngIf="cardProblem != ''">{{ cardProblem }}</mat-error>

      <div class="mt-8 mb-16">
        <mat-checkbox #agree color="primary" class="mat-caption" required="required">I Accept the Terms and Conditions</mat-checkbox>
      </div>

      <button class="full-width" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loading || !agree.checked || cardError">
        <ng-container *ngIf="!loading; else spinner">PAY (TOTAL: $100.00)</ng-container>
        <ng-template #spinner><mat-spinner [diameter]="24"></mat-spinner></ng-template>
      </button>
    </div>
  </mat-card>
</div>
