import { Injectable } from '@angular/core';
import AvaCAS from 'ava-cas-iframe';
import { environment } from '../../environments/environment';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface Business {
  id: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  accountId: string;
  sessionId: string;
  currentBusiness: Business;
  actualCustomerPortalUrl: string;
  actualCustomerInstanceManagerUrl: string;
  instanceId: string;
}

interface InstanceLogin {
  currentUser: User;
  urls: any;
  token: string;
  instanceId: string;
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private cas: AvaCAS;
  currentUser$: Observable<User>;

  constructor(private httpClient: HttpClient) {
    this.cas = new AvaCAS(document, {
      src: this.getActualUrl(),
      postMessageSrc: this.getActualUrl(),
      usersUrl: environment.backendUrl,
      pusher: {
        id: environment.pusherId,
        cluster: 'mt1',
      },
    });
    this.currentUser$ = from(this.cas.init().then((data) => data)).pipe(
      tap((result) => {
        if (!('token' in result)) {
          window.location.replace(environment.loginUrl);
        }
      }),
      map((result: InstanceLogin) => {return {...result, sessionId: result.token}}),
      switchMap((result: InstanceLogin)  => {
        return combineLatest(
          this.toolbarData(),
          of(result),
          this.httpClient.get<{id: string}[]>(`${environment.api}/user/${result.currentUser.id}/own-business`, {'headers': {'session-id': result.token}})
        )
      }),
      map(([first, second, ownAccounts]) => {
        const isBusinessOwner = ownAccounts.filter((ownAccount) => ownAccount.id === first.business.id).length > 0;

        if (!isBusinessOwner) {
          window.location.replace('//'+second.urls.instance);
        }

        return {
          ...first.currentUser,
          sessionId: second.token,
          actualCustomerPortalUrl: second.urls.instance,
          actualCustomerInstanceManagerUrl: second.urls.InstanceManager,
          instanceId: second.instanceId,
        };
      }),
      shareReplay()
    );
  }

  private toolbarData(): Observable<{currentUser: any, business: any}> {
    return from(this.cas.getToolbarData().then((data) => data));
  }

  private getActualUrl(): string
  {
    return window.location.href;
  }
}
