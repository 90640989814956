import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentUserService } from './current-user.service';
import { switchMap } from 'rxjs/operators';

interface Order {
  name: string;
  paymentToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService
  ) { }

  checkDomain(name: string): Observable<{}> {
    return this.currentUserService.currentUser$.pipe(
      switchMap((user) => this.http.get(`${environment.api}/business/${user.currentBusiness.id}/instance/name/${name}`, {'headers': {'session-id': user.sessionId}})),
    );
  }

  createInstance(order: Order): Observable<{}> {
    return this.currentUserService.currentUser$.pipe(
      switchMap(
        user => this.http.post(
          `${environment.api}/business/${user.currentBusiness.id}/instance/${user.instanceId}/order/customer-portal`,
          order,
          {'headers': {'session-id': user.sessionId}}
          )
      )
    )
  }
}
